import React from 'react';
import HeaderThree from "../common/header/HeaderThree";
import Breadcrumb from "../common/breadcrumb/Breadcrumb";
import ContactMap from "../components/contact/ContactMap";
import ContactForm from "../components/contact/ContactForm";
import BrandOne from "../components/brand/BrandOne";
import FooterOne from "../common/footer/FooterOne";
import {Link} from 'react-router-dom';

const Certifications = () => {
    let publicUrl = process.env.PUBLIC_URL+'/'
    return (
        <>
            <HeaderThree />
            <Breadcrumb
                heading="Certifications"
                currentPage="Certifications" 
            />
              <section className="project-one-sec project-one-sec--three">
                   <div className="container-fluid">
                        <div className="sec-title text-center">
                            <div className="sec-title__tagline">
                                <span className="left"></span>
                                <h6>Certifications</h6> <span className="right"></span>
                            </div>
                            <h2 className="sec-title__title" style={{color:"#001659"}}>Our Certifications</h2>
                        </div>
                        <div className="row">
                            {/* Start project One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                        <embed src={publicUrl + "assets/images/MIP/pdfs/SVSMC.png"} type="" width="100%" height="500px" />                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/pdfs/SVSMC.pdf"} target='_blank'>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>SVSMC</Link></h2>
                                            <p>Work Done Certificate</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End project One Single */}

                            {/* Start project One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                        <embed src={publicUrl + "assets/images/MIP/pdfs/Vctpl-s.png"} type="" width="100%" height="500px" />                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/pdfs/Vctpl -supply reclamation.pdf"} target='_blank'>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL- supply reclamation</Link></h2>
                                            <p>Work Done Certificate</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End project One Single */}

                            {/* Start project One Single */}
                            {/* <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                        <embed src={publicUrl + "assets/images/MIP/pdfs/Vctpl-r.png"} type="" width="100%" height="500px" />                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/pdfs/Vctpl -reclamation material -LOA & Work completion certificate.pdf"} target='_blank'>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL</Link></h2>
                                            <p>Letter of Award</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* End project One Single */}

                            {/* Start project One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                        <embed src={publicUrl + "assets/images/MIP/pdfs/Vctpl-y.png"} type="" width="100%" height="500px" />                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/pdfs/Vctpl - yard development.pdf"} target='_blank'>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>VCTPL - yard development</Link></h2>
                                            <p>Work Done Certificate</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End project One Single */}

                            {/* Start project One Single */}
                            {/* <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                        <embed src={publicUrl + "assets/images/MIP/pdfs/Vctpl-my.png"} type="" width="100%" height="500px" />                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/pdfs/Vctpl - Main Yard LOA & Work completion certificate.pdf"} target='_blank'>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Vctpl - Main Yard LOA & Work </Link></h2>
                                            <p>Completion Certificate</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* End project One Single */}

                            {/* Start project One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                        <embed src={publicUrl + "assets/images/MIP/pdfs/Saint G1.png"} type="" width="100%" height="500px" />                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/pdfs/Saint Gobain-1 LOA & Work completion.pdf"} target='_blank'>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>Saint Gobain-1</Link></h2>
                                            <p>LOA & Work completion</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End project One Single */}

                            {/* Start project One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                        <embed src={publicUrl + "assets/images/MIP/pdfs/PO for Roads and drains.png"} type="" width="100%" height="500px" />                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/pdfs/PO for Roads and drains - Glass area - SGIIPL.pdf"} target='_blank'>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>PO for Roads and drains - Glass area - SGIIPL</Link></h2>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End project One Single */}

                            {/* Start project One Single */}
                            <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                        <embed src={publicUrl + "assets/images/MIP/pdfs/MIP Key Projects.png"} type="" width="100%" height="500px" />                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/pdfs/MIP Key Projects.pdf"} target='_blank'>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>MIP Key Projects</Link></h2>
                                            <p>MIP Key Projects</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End project One Single */}
                            {/* <div className="col-xl-4 col-lg-4">
                                <div className="project-one__single wow fadeInUp" data-wow-delay="0ms" data-wow-duration="1500ms">
                                    <div className="project-one__single-img">
                                        <div className="inner">
                                        <embed src={publicUrl + "assets/images/MIP/pdfs/MIP Turnover.png"} type="" width="100%" height="500px" />                                            <div className="project-one__link">
                                                <a className="img-popup" href={publicUrl+"assets/images/MIP/pdfs/MIP Financial Turnover.pdf"} target='_blank'>
                                                    <span className="icon-plus-sign"></span>
                                                </a>
                                            </div>
                                        </div>
                                        <div className="content-box">
                                            <h2><Link to={process.env.PUBLIC_URL + `#`}>MIP Financial Turnover</Link></h2>
                                            <p>MIP Financial Turnover</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
         
        

            
            
            <FooterOne />
        </>
    )
}

export default Certifications;
import React from 'react';
import { CgChevronDown } from 'react-icons/cg';
import {Link} from "react-router-dom";

const Nav = () => {
    return (
        <div className="main-menu text-center">
            <nav>
                <ul className="main-menu__list">
                    <li className="">
                        <Link to={process.env.PUBLIC_URL + `/`}>Home</Link>
                        {/* <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/`}>Home</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/home-two`}>Home Two</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/home-three`}>Home Three</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/home-four`}>Home Four</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/home-five`}>Home Five</Link></li>
                            <li className="dropdown">
                                <Link to={process.env.PUBLIC_URL + `/`}>Header Styles</Link>
                                <ul>
                                    <li><Link to={process.env.PUBLIC_URL + `/`}>Header One</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + `/home-two`}>Header Two</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + `/home-three`}>Header Three</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + `/home-four`}>Header Four</Link></li>
                                    <li><Link to={process.env.PUBLIC_URL + `/home-five`}>Header Five</Link></li>
                                </ul>
                            </li>
                        </ul> */}
                    </li>
                    <li className="">
                        <Link to={process.env.PUBLIC_URL + `/about-one`}>About</Link>
                        {/* <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/about-one`}>About One</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/about-two`}>About Two</Link></li>
                        </ul> */}
                    </li>
                    {/* <li><Link to={process.env.PUBLIC_URL +  `assets/images/MIP/pdfs/MIP Profile.pdf`}  href="assets/images/MIP/pdfs/MIP Profile.pdf" target="_blank">Profile</Link></li> */}
                    <li className="">
                        <Link to={process.env.PUBLIC_URL + `#`}>Services<CgChevronDown /></Link>
                        <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/services/Roads-and-Drains`}>Roads and Drains</Link></li>
                            {/* <li><Link to={process.env.PUBLIC_URL + `/services/Reservoirs`}>Reservoirs</Link></li> */}
                            <li><Link to={process.env.PUBLIC_URL + `/services/Marine-Rock-Bund-Works`}>Marine Rock Bund Works</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/services/Container-Yard-Development`}>Container Yard Development</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/services/Box-Culverts-Construction`}>Box Culverts Construction</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/services/Civil-works`}>Civil works</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/services/Compound-wall-yard-development-works`}>Compound wall yard development works</Link></li>
                            {/* <li><Link to={process.env.PUBLIC_URL + ` `}>Marine works</Link></li> */}
                            <li><Link to={process.env.PUBLIC_URL + `/services/Warehouses`}>Warehouses</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/services/Reclimation-works`}>Reclimation works</Link></li>
                            {/* <li><Link to={process.env.PUBLIC_URL + `/services/Minerals-Material-Transport`}>Minerals & Material Transport</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/services/Gravel-supply`}>Gravel supply</Link></li> */}
                            <li><Link to={process.env.PUBLIC_URL + `/services/Underground-drainages`}>Underground drainages</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/services/EPC-works`}>EPC works</Link></li>
                         
                        </ul>
                    </li>
                    <li className="">
                        <Link to={process.env.PUBLIC_URL + `/portfolio`}>Gallery</Link>
                        {/* <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/portfolio`}>Portfolio</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/portfolio-details`}>Portfolio Details</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/team`}>Team</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/team-details`}>Team Details</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/testimonials`}>Testimonials</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/pricing`}>Pricing</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/faq`}>Faq</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/error`}>Error</Link></li>
                        </ul> */}
                    </li>
                    <li><Link to={process.env.PUBLIC_URL + `/Certifications`}>Certifications</Link></li>
                    <li className="">
                        <Link to={process.env.PUBLIC_URL + `/Careers`}>Careers</Link>
                        {/* <ul>
                            <li><Link to={process.env.PUBLIC_URL + `/blog`}>Blog</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/blog-grid`}>Blog Grid</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/blog-list`}>Blog List</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/blog-standard`}>Blog Standard</Link></li>
                            <li><Link to={process.env.PUBLIC_URL + `/blog-details`}>Blog Details</Link></li>
                        </ul> */}
                    </li>
                  
                    <li><Link to={process.env.PUBLIC_URL + `/contact`}>Contact</Link></li>
                </ul>
            </nav>
        </div>
    )
}

export default Nav;


